import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeHeading } from '../../common/typography/TypeHeading';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.spacing.SPACING_2}rem;
  padding-right: ${props => props.theme.spacing.SPACING_2}rem;
  margin-bottom: 8rem;

  ${minWidth(Breakpoint.M)} {
    margin-bottom: 26.8rem;
    padding-left: ${props => props.theme.spacing.SPACING_1}rem;
    padding-right: ${props => props.theme.spacing.SPACING_1}rem;
  }
`;

export const Title = styled(TypeHeading)`
  color: white;
  text-transform: uppercase;
  font-family: ${props => props.theme.font.helveticaNeueRegular};
  display: block;
  margin-bottom: 8rem;
  width: 90%;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 16rem;
    width: 60%;
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-end;
  justify-content: space-evenly;
  ${minWidth(Breakpoint.M)} {
    flex-direction: row;
    width: 64%;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 0;
  }
`;
export const ListTitle = styled(TypeParagraph)`
  color: white;
  opacity: 0.7;
  font-family: ${props => props.theme.font.cardinalItalic};
  display: block;
  opacity: 0.7;
  letter-spacing: 0.5px;
    margin-bottom: 2rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 5.3rem;
  }
`
export const ListItem = styled(TypeParagraph)`
  white-space: pre-line;
  color: white;
  font-family: ${props => props.theme.font.helveticaNeueLight};
  line-height: 250%;

  a:hover {
    text-decoration: underline;
  }
`;
