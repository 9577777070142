import React, { forwardRef, useState } from "react"
import { TypeHeadingSizes } from '../../common/typography/TypeHeading';
import { Container, Title, Nav, Cover, CoverContainer } from './styled';

export interface NextProjectProps { className?: string, project: any };

const NextProject = forwardRef((props: NextProjectProps, ref) => {

  let [title, setTitle] = useState(props.project.Title);

  const CoverData = {
    URL: null,
    Upload: {
      ...props.project.Cover
    }
  }

  const mouseOver = () => {
    setTitle('NEXT');
  }

  const mouseOut = () => {
    setTitle(props.project.Title);
  }

  return (
    <Container className={props.className}>
      <Nav to={`/case/${props.project.Slug}`}>
        <Title onMouseOver={mouseOver} onMouseOut={mouseOut} size={TypeHeadingSizes.H1}>
          {title}
        </Title>
      </Nav>
      <CoverContainer>
        <Cover data={CoverData} />
      </CoverContainer>
    </Container>
  )
})

export default NextProject;
