import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeParagraph } from '../../common/typography/TypeParagraph';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
  padding-left: ${props => props.theme.spacing.SPACING_2}rem;
  padding-right: ${props => props.theme.spacing.SPACING_2}rem;

  ${minWidth(Breakpoint.M)} {
    flex-direction: row;
    flex-direction: initial;
    padding-left: ${props => props.theme.spacing.SPACING_1}rem;
    padding-right: ${props => props.theme.spacing.SPACING_1}rem;
  }
`;

export const Cover = styled(Asset)`
  width: 44%;
  align-self: center;
  ${minWidth(Breakpoint.M)} {
    align-self: initial;
  }
`;

export const InfoWrapper = styled.div`
  width: 56%;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  ${minWidth(Breakpoint.M)} {
    padding-top: 16%;
  }
`;

export const Info = styled.div`
  padding-left: 0;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  ${minWidth(Breakpoint.M)} {
    padding-left: 24%;
    margin-bottom: 12rem;
  }
`

export const Title = styled(TypeParagraph)`
  color: white;
  opacity: 0.7;
  font-family: ${props => props.theme.font.cardinalItalic};
  margin-bottom: 2.5rem;
`;

export const Email = styled(TypeParagraph)`
  color: white;
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-decoration: underline;
  margin-bottom: 1.5rem;
`;

export const Value = styled(TypeParagraph)`
  color: white;
  font-family: ${props => props.theme.font.helveticaNeueLight};
`;
