import React, { forwardRef } from "react"
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, Cover, InfoWrapper, Info, Title, Value, Email } from './styled';

export interface AboutContactProps { className?: string, data: any };

const AboutContact = forwardRef((props: AboutContactProps, ref) => {

  return (
    <Container id='contact' className={props.className}>
      <Cover scroll-speed={1.5} data={{Upload: props.data.Image}} />
      <InfoWrapper>
        <Info>
          <Title size={TypeParagraphSizes.XL}>{props.data.ContactTitle}</Title>
          <Email as='a' href={`mailto:${props.data.EmailContact}`} size={TypeParagraphSizes.XL}>{props.data.EmailContact}</Email>
          <Value size={TypeParagraphSizes.XL}>{props.data.Telephone}</Value>
        </Info>
        <Info>
          <Title size={TypeParagraphSizes.XL}>{props.data.JobsTitle}</Title>
          <Email as='a' href={`mailto:${props.data.EmailJobs}`} size={TypeParagraphSizes.XL}>{props.data.EmailJobs}</Email>
        </Info>
      </InfoWrapper>
    </Container>
  )
})

export default AboutContact;
