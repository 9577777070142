import styled from 'styled-components';
import VimeoPlayer from 'react-player/vimeo'
import YouTubePlayer from 'react-player/youtube'
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';

export const Container = styled.div`
  display: block;
`;

export const Image = styled.img`
  object-fit: contain;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 100%;
`

export const YTPlayer = styled(YouTubePlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const VMPlayer = styled(VimeoPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const PlayButton = styled.img`
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  z-index: 2;
  cursor: pointer;
  ${minWidth(Breakpoint.M)} {
    width: 15rem;
    height: 15rem;
  }
`

export const VideoWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding-top: ${props => `calc(${100 / (props.vwidth / props.vheight)}% + 1px)`}; /* Player ratio: 100 / (1280 / 720) */

  & iframe {
    width: calc(100% - 2px) !important;
    overflow: hidden;
  }
`
