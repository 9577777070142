import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 28rem;
  }
`;

export const LeftColum = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RightColum = styled.div`
  width: 50%;
  ${minWidth(Breakpoint.M)} {
    width: 45%;
  }
`

export const Asset1 = styled(Asset)`
  margin-bottom: 0rem;
  margin-top: 14%;
  width: 100%;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 14rem;
  }
`

export const Asset2 = styled(Asset)`
  margin-bottom: 0rem;
  margin-left: -1rem;
  width: 100%;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 14rem;
    margin-left: -12rem;
  }
`

export const Asset3 = styled(Asset)`
  width: 88%;
  z-index: 2;
`

export const Asset4 = styled(Asset)`
  margin-left: -1rem;
  width: 100%;
  ${minWidth(Breakpoint.M)} {
    width: 80%;
    margin-left: -16rem;
  }
`
