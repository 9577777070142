import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: block;
  margin-bottom: 7rem;
  ${minWidth(Breakpoint.M)} {
    height: 100vh;
    margin-bottom: 28rem;
  }
  overflow: hidden;
`;

export const Asset1 = styled(Asset)`
  width: 100%;
`;
