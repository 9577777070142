import React, { forwardRef, useContext, useEffect, useRef, useState } from "react"
import gsap from 'gsap';
import { Link } from 'gatsby';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, Column1, ColumnLabel, Column2, Column3, Thumb, ThumbWrapper } from './styled';

export interface ArchiveItemProps { className?: string, data: any, delay: any };

const ArchiveItem = forwardRef((props: ArchiveItemProps, ref) => {
  const el = useRef();
  const thumb = useRef();
  const column = useRef();
  const [thumbX, setThumbX] = useState(0);
  const [active, setActive] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const date = new Date(props.data.Published).getFullYear();

  const getCategories = () => {
    let label = '';
    props.data.categories.forEach(({ Name }) => {
      label += `${Name}, `
    })
    label = label.slice(0, -2);
    return label;
  }

  const checkIfCoverIsLandscape = () => {
    if (props.data.Cover.formats && props.data.Cover.formats.large) {
      if (props.data.Cover.formats.large.height <= props.data.Cover.formats.large.width) {
        setIsLandscape(true);
      }
    }

    if (props.data.Cover.formats && props.data.Cover.formats.medium) {
      if (props.data.Cover.formats.medium.height < props.data.Cover.formats.medium.width) {
        setIsLandscape(true);
      }
    }
  }

  useEffect(() => {
    checkIfCoverIsLandscape();
    if (el.current) {
      const initBounds = (column as any).current.getBoundingClientRect();
      const area = window.innerWidth - initBounds.x;
      const x = initBounds.x + (Math.random() * area) - window.innerWidth * 0.29;
      setThumbX(Math.round(x));
      gsap.fromTo(el.current, { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 1, delay: props.delay / 10, ease: 'expo.out' })
    }
  }, [])

  const mouseOver = () => {
    setActive(true);
    gsap.fromTo(thumb.current, { opacity: 0}, { opacity: 1, duration: 0.15 });
    gsap.fromTo(thumb.current, { x:thumbX, y: '-20%' }, {y: '-30%', duration: 0.75, ease: 'expo.out', x: thumbX });
  }

  const mouseOut = () => {
    setActive(false);
    gsap.killTweensOf(thumb.current);
    gsap.to(thumb.current, { opacity: 0, x: thumbX, duration: 0.25, scale: 1, y: '-30%' });
  }

  return (
    <Container active={active} onMouseEnter={mouseOver} onMouseLeave={mouseOut} ref={el} className={props.className}>
          <ThumbWrapper landscape={isLandscape} ref={thumb} x={thumbX} >
            <Thumb data={{Upload: props.data.Cover}}></Thumb>
          </ThumbWrapper>
            <Column1 active={active} ref={column}>
              <ColumnLabel as='p' size={TypeParagraphSizes.S}>
                <Link to={`/case/${props.data.Slug}`}>
                  {props.data.Title}
                </Link>
              </ColumnLabel>
            </Column1>
            <Column2 active={active}>
              <ColumnLabel as='p' size={TypeParagraphSizes.S}>
                <Link to={`/case/${props.data.Slug}`}>
                  {getCategories()}
                </Link>
              </ColumnLabel>
            </Column2>
            <Column3 active={active}>
              <ColumnLabel as='p' size={TypeParagraphSizes.S}>
                <Link to={`/case/${props.data.Slug}`}>
                  {date}
                </Link>
              </ColumnLabel>
            </Column3>
      </Container>
  )
})

export default ArchiveItem;
