import React, { forwardRef, useRef, useImperativeHandle } from "react"
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { SocialWrapper, Container, Copyright1, CopyrightWrapper, BottomWrapper, ContactWrapper,ContactEmail, ContactCopy, AddressCopy, SocialLink } from './styled';

export interface FooterProps { className?: string, theme?: string, data: any };

const Footer = forwardRef((props: FooterProps, ref) => {

  const footerData = props.data.edges[0].node.Footer;

  const copyright = footerData.Copyright;
  const telephone = footerData.Telephone;
  const email = footerData.Email;
  const address = footerData.Address;
  const social = footerData.SocialMedia;

  const renderSocial = social.map((s, i) => {
    return <SocialLink color={props.theme} target="_blank" key={i} size={TypeParagraphSizes.S} as={'a'} href={s.URL}>{s.Name}</SocialLink>
  })

  return (
    <Container className={props.className}>
      <CopyrightWrapper>
        <Copyright1 color={props.theme} size={TypeParagraphSizes.S}>{copyright}</Copyright1>
      </CopyrightWrapper>

      <BottomWrapper>
        <ContactWrapper>
          <ContactCopy color={props.theme} size={TypeParagraphSizes.S}>{telephone}</ContactCopy>
          <ContactEmail target="_blank" as={'a'} href={`mailto:${email}`} color={props.theme} size={TypeParagraphSizes.S}>{email}</ContactEmail>
        </ContactWrapper>
        {(address && address.length) > 0 &&
          <AddressCopy color={props.theme} size={TypeParagraphSizes.S}>{address}</AddressCopy>
        }
        <SocialWrapper>
          {renderSocial}
        </SocialWrapper>
      </BottomWrapper>
    </Container>
  )
})

export default Footer;
