export const aboutData = (data) => {
  const aboutData: any = data.allStrapiAbout.edges[0].node;
  let about:any = {};
  about.Title = aboutData.Title;
  about.Description = aboutData.Description;
  about.Cover = aboutData.Cover;
  about.components = [];
  aboutData.Components.forEach((comp, i) => {
    about.components.push({ ... comp});
  })
  return about;
}
