import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeParagraph } from '../../common/typography/TypeParagraph';
import Asset from '../Asset';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 7rem;
  justify-content: space-between;
  padding-left: ${props => props.theme.spacing.SPACING_2}rem;
  padding-right: ${props => props.theme.spacing.SPACING_2}rem;
  opacity: 0;

  position: relative;
  pointer-events: auto;
  cursor: pointer;
  height: 7rem;

  ${minWidth(Breakpoint.M)} {
    justify-content: flex-end;
    padding-left: ${props => props.theme.spacing.SPACING_1}rem;
    padding-right: ${props => props.theme.spacing.SPACING_1}rem;
    & p {
      opacity: ${props => props.active ? 1 : 0.3};
    }
  }
  z-index:${props => props.active ? 2 : 0};

`;

export const ThumbWrapper = styled.div`
  position: absolute;
  height: ${props => props.landscape ? `50vh` : 'initial'};
  width: ${props => !props.landscape ? `28vw` : 'initial'};
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  top: 0;
  left: 0;
`

export const Thumb = styled(Asset)`
  width: 100%;
  height: 100%;
  display: none;
  ${minWidth(Breakpoint.M)} {
    display: block;
  }
`;

export const ColumnLabel = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  opacity: 1;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0;
`;

export const Column1 = styled.div`
  flex-shrink: 0;
  position: relative;
  z-index:${props => props.active ? 2 : 0};
  width: 78%;
  display: flex;
  align-items: center;
  ${minWidth(Breakpoint.M)} {
    width: 30%;
  }
`
export const Column2 = styled.div`
  flex-shrink: 0;
  width: 28%;
  z-index:${props => props.active ? 2 : 0};
  align-items: center;
  display: none;
  ${minWidth(Breakpoint.M)} {
    display: flex;
    width: 37%;
  }
`

export const Column3 = styled.div`
  width: 22%;
  text-align: right;
  z-index:${props => props.active ? 2 : 0};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${minWidth(Breakpoint.M)} {
    width: 6%;
  }
`
