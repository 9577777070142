import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeHeading } from '../../common/typography/TypeHeading';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  max-width: 100%;
  margin-bottom: 14rem;

  ${minWidth(Breakpoint.M)} {
    margin-bottom: 20rem;
    align-items: flex-end;
    padding-left: ${props => props.theme.spacing.SPACING_1}rem;
    padding-right: ${props => props.theme.spacing.SPACING_1}rem;
    max-width: 75%;
  }
`;

export const Title = styled(TypeHeading)`
  font-family: ${props => props.theme.font.helveticaNeueRegular};
  line-height: 110%;
  width: 100%;
  display: block;
  margin-bottom: 4rem;
  text-transform: uppercase;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 10rem;
  }
`;
export const Subtitle = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  display: block;
  margin-bottom: 3rem;
  padding-left: 5rem;
  text-transform: uppercase;
`;
export const Description = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.cardinalRegular};
  text-indent: 5rem;
  display: block;
  line-height: 120%;
`;

export const Info = styled.div`
  width: 100%;
  ${minWidth(Breakpoint.M)} {
    width: 43%;
  }
`
