import React, { forwardRef } from "react"
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, List, Item, Label, Value } from './styled';

export interface CreditsProps { className?: string, data: any };

const Credits = forwardRef((props: CreditsProps, ref) => {

  const items = props.data.List.map((credit, i) => {
    return (<Item key={i}>
      <Label size={TypeParagraphSizes.XS}>{credit.Name}</Label>
      <Value size={TypeParagraphSizes.S}>{credit.Value}</Value>
    </Item>)
  })

  return (
    <Container className={props.className}>
      <List>
        {items}
      </List>
    </Container>
  )
})

export default Credits;
