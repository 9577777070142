import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { maxWidth, minWidth } from '../../../util/style/mixins/respondTo';
import { TypeHeading } from '../../common/typography/TypeHeading';
import { TypeParagraph } from '../../common/typography/TypeParagraph';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: block;
  margin-bottom: 28rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 28rem;
  }
`;

export const Content = styled.div`
  width: 96%;
  margin: 0 auto;
  text-align: center;
  padding-top: 17vw;
  margin-bottom: 15vw;
  position:relative;
  z-index: 1;
  ${minWidth(Breakpoint.M)} {
    width: 80%;
  }
`

export const CoverWrapper = styled.div`
  position: relative;
  z-index: 3;
`

export const Cover = styled(Asset)`
  width: 55vw;
  margin: 0 auto;
  position:relative;;
  top: 20rem;
  ${minWidth(Breakpoint.M)} {
    width: 55%;
  }
`

export const Title = styled(TypeHeading)`
  text-transform: uppercase;
  font-family: ${props => props.theme.font.helveticaNeueRegular};
  display: block;
  margin-bottom: 3vw;
  opacity: 0;
  `

export const Subtitle = styled(TypeHeading)`
  font-family: ${props => props.theme.font.cardinalRegular};
  width: 90%;
  display: block;
  margin: 0 auto;
  text-align: center;
  line-height: 120%;
  margin-bottom: 5vw;
  opacity: 0;
  ${minWidth(Breakpoint.M)} {
    width: 80%;
  }
  `

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 96%;
  margin: 0 auto;
  opacity: 0;
  ${minWidth(Breakpoint.M)} {
    flex-direction: row;
    max-width: 90%;
    max-width: 70%;
  }
`

export const InfoWrapper = styled.div`
  margin-bottom: 1rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 0;
  }
`

export const Small = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.cardinalItalic};
  opacity: 0.7;
  letter-spacing: 0.5px;
  margin-right: 15px;
`

export const InfoLabel = styled(TypeParagraph)`
  text-transform: uppercase;
  font-family: ${props => props.theme.font.helveticaNeueLight};
  ${maxWidth(Breakpoint.M)} {
    font-size: 1rem;
  }
`
