import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: flex;
  padding-top: 13rem;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 100vh;
  ${minWidth(Breakpoint.M)} {
    justify-content: flex-end;
    padding-top: 26rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 7rem;
  padding-left: ${props => props.theme.spacing.SPACING_2}rem;
  padding-right: ${props => props.theme.spacing.SPACING_2}rem;
  margin-bottom: 2rem;

  ${minWidth(Breakpoint.M)} {
    justify-content: flex-end;
    padding-left: ${props => props.theme.spacing.SPACING_1}rem;
    padding-right: ${props => props.theme.spacing.SPACING_1}rem;
    margin-bottom: 6rem;
  }
`

export const Items = styled.div`
  display: block;
`

export const ColumnLabel = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.cardinalItalic};
  opacity: 0.7;
`;

export const Column1 = styled.div`
  flex-shrink: 0;
  width: 78%;
  ${minWidth(Breakpoint.M)} {
    width: 30%;
  }
`
export const Column2 = styled.div`
  width: 28%;
  flex-shrink: 0;
  display: none;
  ${minWidth(Breakpoint.M)} {
    display: block;
    width: 37%;
  }
`

export const Column3 = styled.div`
  text-align: right;
  width: 22%;
  ${minWidth(Breakpoint.M)} {
    width: 6%;
  }
`
