import React, { forwardRef } from "react"
import { Asset1, Container, Asset2 } from './styled';

export interface AssetTwoProps { className?: string, data: any };

const AssetTwo = forwardRef((props: AssetTwoProps, ref) => {

  return (
    <Container className={props.className}>
      <Asset1 autoplay={true} scroll-speed={2} data={props.data.Asset1} />
      <Asset2 autoplay={true} data={props.data.Asset2} />
    </Container>
  )
})

export default AssetTwo;
