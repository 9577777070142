import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
`;

export const Asset1 = styled(Asset)`
  width: 55%;
`;

export const Asset2 = styled(Asset)`
  width: 50%;
  transform: translateX(-100%) translateY(-14rem);
  ${minWidth(Breakpoint.M)} {
    width: 44%;
    transform: translateX(-100%) translateY(-28rem);
  }
`;
