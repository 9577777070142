import React, { forwardRef } from "react"
import { Container, Asset1 } from './styled';

export interface AssetFullProps { className?: string, data: any };

const AssetFull = forwardRef((props: AssetFullProps, ref) => {

  return (
    <Container className={props.className}>
      <Asset1 autoplay={false} data={props.data.Asset1}></Asset1>
    </Container>
  )
})

export default AssetFull;
