import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 28rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6%;
  width: 90%;
`

export const Asset1 = styled(Asset)`
  width: 44%;
`;

export const Asset2 = styled(Asset)`
  width: 49%;
`;
export const Asset3 = styled(Asset)`
  width: 62%;
`;
