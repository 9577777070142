import { Link } from "gatsby"
import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeHeading } from '../../common/typography/TypeHeading';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: block;
  height: 50vh;
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0;
  ${minWidth(Breakpoint.M)} {
    min-height: 100vh;
    margin-bottom: 0;
    margin-bottom: 14rem;
  }
  background-color: transparent;
`;

export const Nav = styled(Link)`
`

export const Title = styled(TypeHeading)`
  text-transform: uppercase;
  font-family: ${props => props.theme.font.helveticaNeueRegular};
  display: block;
  position: absolute;
  color: black;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  text-align: center;
  width: 80%;
  padding-left: 24rem;
  padding-right: 24rem;
  height: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`

export const CoverContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
`

export const Cover = styled(Asset)`
  max-height: 65vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;

  & > img {
    width: initial;
    max-height: 90vh;
    height: 100%;
  }
`
