import React, { forwardRef, useContext, useEffect, useRef } from "react"
import { Container } from './styled';
import ProjectHero from 'src/components/organisms/ProjectHero';
import scroller from 'src/util/scroller';
import { caseData } from './data';
import WebGLApp from '../../webgl/WebGLApp';
import Footer from '../../molecules/Footer';
import AssetFour from 'src/components/organisms/AssetFour';
import AssetThree from 'src/components/organisms/AssetThree';
import AssetTwo from 'src/components/organisms/AssetTwo';
import BlockText from 'src/components/organisms/BlockText';
import Credits from 'src/components/organisms/Credits';
import NextProject from 'src/components/organisms/NextProject';
import AssetFull from 'src/components/organisms/AssetFull';
import { AppContext } from '../../Layout/Layout';

const CasePage = forwardRef((props: any, ref) => {
  let el = useRef();
  const data = props.data;
  const slug = props.slug;
  const project: any = caseData(data, slug);
  let context = useContext(AppContext);

  const getComponent = (component, i) => {
    switch (component.strapi_component) {
      case 'project-block.four-images':
        return <AssetFour key={i} data={component} />
      case 'project-block.three-images':
        return <AssetThree key={i} data={component} />
      case 'project-block.two-assets':
        return <AssetTwo key={i} data={component} />
      case 'project-block.text-block':
        return <BlockText key={i} data={component} />
      case 'project-block.fullscreen-asset':
        return <AssetFull key={i} data={component} />
      case 'credits.credits':
        return <Credits key={i} data={component} />
      default:
        break;
    }
  }

  const renderComponents = () => {
    return project.Component.map((comp, i) => {
      return getComponent(comp, i);
    })
  }

  useEffect(() => {
    context.setBackground('white');
    WebGLApp.caseView.initialize(el.current)
    WebGLApp.caseView.transitionIn()
    scroller.init(el.current);
    return () => {
      scroller.destroy();
      WebGLApp.caseView.transitionOut()
    }
  }, [])
  return (
    <Container data-scroll-section data-scroll ref={el}>
      <ProjectHero project={project} />
      {renderComponents()}
      <NextProject project={project.next} />
      <Footer theme='black' data={props.data.allStrapiFooter} className='footer' />
    </Container>
  )
})

export default CasePage;



