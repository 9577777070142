import React, { forwardRef, useEffect, useRef, useState } from "react"
import { TypeHeadingSizes } from '../../common/typography/TypeHeading';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import gsap from 'gsap';
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import { Container, Content,Title, Subtitle, CoverWrapper, Info, InfoWrapper, Small, InfoLabel, Cover } from './styled';

export interface ProjectHeroProps { className?: string, project: any };

const ProjectHero = forwardRef((props: ProjectHeroProps, ref) => {

  const title = useRef();
  const subtitle = useRef();
  const info = useRef();
  const cover = useRef();
  const date = new Date(props.project.Published).getFullYear();

  const getCategories = () => {
    let label = '';
    props.project.categories.forEach(({ Name }) => {
      label += `${Name}, `
    })
    label = label.slice(0, -2);
    return label;
  }

  const CoverData = {
    URL: null,
    Upload: {
      ...props.project.Cover
    }
  }

  const truncatedSubtitle = props.project.Subtitle.replace(/^(.{70}[^\s]*).*/, "$1");


  useEffect(() => {
    const splitTitle = Splitting({ target: title.current, by: 'chars' });
    const tl = gsap.timeline({});
    (subtitle as any).current.style.opacity = 1;
    (title.current as any).style.opacity = 1;
    (info.current as any).style.opacity = 1;
    const totalChars = splitTitle[0].chars.length;
    splitTitle[0].chars.forEach((c, i) => {
        tl.fromTo(c, { y: 40, opacity: 0 }, { y: 0, opacity: 1 }, i / (totalChars * 1.5));
    })
    tl.fromTo(subtitle.current, { y: 40, opacity: 0 }, { duration: 1, y: 0, opacity: 1, ease: 'expo.out' }, '-=0.25')
    tl.fromTo(info.current, { y: 20, opacity: 0 }, { duration: 1, y: 0, opacity: 1, ease: 'expo.out' }, '-=0.6')
    tl.fromTo(cover.current, { y: 40, opacity: 0 }, { duration: 1, y: 0, opacity: 1, ease: 'expo.out' }, '-=0.6')
    tl.play();
  }, [])

  return (
    <Container className={props.className}>
      <Content data-scroll-offset='0%,-40%' data-scroll data-scroll-speed={-5}>
        <Title ref={title} size={TypeHeadingSizes.H1}>
          {props.project.Title}
        </Title>
        <Subtitle ref={subtitle} size={TypeHeadingSizes.H3}>{truncatedSubtitle}</Subtitle>
        <Info  ref={info}>
          <InfoWrapper>
            <Small size={TypeParagraphSizes.XS}>
              Categories
            </Small>
            <InfoLabel size={TypeParagraphSizes.S}>{getCategories()}</InfoLabel>
          </InfoWrapper>
          <InfoWrapper>
            <Small size={TypeParagraphSizes.XS}>
              Published
            </Small>
            <InfoLabel size={TypeParagraphSizes.S}>{date}</InfoLabel>
          </InfoWrapper>
        </Info>
      </Content>
      <CoverWrapper ref={cover}>
        <Cover data={CoverData} />
      </CoverWrapper>
    </Container>
  )
})

export default ProjectHero;
