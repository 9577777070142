export const archiveData = (data) => {
  let projects:any = [];
  projects = data.allStrapiProjects.edges.map(({ node }, i) => {
    return { ... node};
  })
  projects = projects.sort((a, b): any => {
    return (new Date(b.Published) as any) - (new Date(a.Published) as any);
  })
  return projects;
}
