import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: block;
  width: 100vw;
  height: 50vh;
  position: relative;
  ${minWidth(Breakpoint.M)} {
    height: 100vh;
  }
`;

export const CopyrightWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  ${minWidth(Breakpoint.M)} {
    top: 75%;
  }
`

export const Copyright1 = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  color: ${props => props.color ? props.color : 'black'};
`;

export const ContactWrapper = styled.div`
  text-align: center;
  margin-bottom: 12px;
`;

export const ContactCopy = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  color: ${props => props.color ? props.color : 'black'};
`;

export const ContactEmail = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  color: ${props => props.color ? props.color : 'black'};
  &:hover {
    text-decoration: underline;
  }
`;

export const AddressCopy = styled(TypeParagraph)`
  margin-bottom: 12px;
  font-family: ${props => props.theme.font.cardinalRegular};
  text-transform: uppercase;
  display: block;
  color: ${props => props.color ? props.color : 'black'};
`;

export const BottomWrapper = styled.div`
  position: absolute;
  bottom: 6vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`

export const SocialWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const SocialLink = styled(TypeParagraph)`
  pointer-events: auto;
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  display: block;
  &:nth-child(2) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  &:hover {
    text-decoration: underline;
  }
  color: ${props => props.color ? props.color : 'black'};
`
