import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  margin-bottom: 7rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 12.5rem;
  }
`;

export const Asset1 = styled(Asset)`
  width: 55%;
`;

export const Asset2 = styled(Asset)`
  width: 100%;
`;

export const ScrollWrapper = styled.div`
  width: 44%;
  position: relative;
  margin-top: 30%;
  transform: translateX(-4rem);
  ${minWidth(Breakpoint.M)} {
    transform: translateX(-9.4rem);
  }
`
