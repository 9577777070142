import React, { forwardRef, useContext, useEffect, useRef } from "react"
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import gsap from 'gsap';
import { TypeParagraph, TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, Headline, Cover, CoverWrapper, Description } from './styled';
import { aboutData } from './data';
import { TypeHeadingSizes } from '../../common/typography/TypeHeading';
import WebGLApp from '../../webgl/WebGLApp';
import scroller from 'src/util/scroller';
import AboutTwoAssets from '../../organisms/AboutTwoAssets';
import AboutServices from '../../organisms/AboutServices';
import AboutFullscreenAsset from '../../organisms/AboutFullscreenAsset';
import AboutContact from '../../organisms/AboutContact';
import Footer from '../../molecules/Footer';
import { AppContext } from '../../Layout/Layout';

const AboutPage = forwardRef((props: any, ref) => {

  const data = aboutData(props.data);
  let el = useRef();
  let context = useContext(AppContext);
  const title = useRef();
  const subtitle = useRef();
  const cover = useRef();

  useEffect(() => {
    context.setBackground('black');
    WebGLApp.aboutView.initialize(el.current)
    WebGLApp.aboutView.transitionIn()
    scroller.init(el.current);
    const splitTitle = Splitting({ target: title.current, by: 'chars' });
    const tl = gsap.timeline({});
    const totalChars = splitTitle[0].chars.length;
    splitTitle[0].chars.forEach((c, i) => {
        tl.fromTo(c, { y: 40, opacity: 0 }, { y: 0, opacity: 1 }, i / (totalChars * 1.5));
    })
    tl.fromTo(subtitle.current, { y: 40, opacity: 0 }, { duration: 1, y: 0, opacity: 1, ease: 'expo.out' }, '-=0.25')
    tl.fromTo(cover.current, { y: 40, opacity: 0 }, { duration: 1, y: 0, opacity: 1, ease: 'expo.out' }, '-=0.6')
    tl.play();
    return () => {
      scroller.destroy();
      WebGLApp.aboutView.transitionOut()
    }
  }, [])

  const getComponent = (component, i) => {
    switch (component.strapi_component) {
      case 'about-block.two-images':
        return <AboutTwoAssets key={i} data={component} />
      case 'about-block.services':
        return <AboutServices key={i} data={component} />
      case 'about-block.fullscreen-image':
        return <AboutFullscreenAsset key={i} data={component} />
      case 'about-block.contact':
        return <AboutContact key={i} data={component} />
      default:
        break;
    }
  }

  const renderComponents = () => {
    return data.components.map((comp, i) => {
      return getComponent(comp, i);
    })
  }

  return (
    <Container data-scroll-section data-scroll ref={el}>
      <Headline data-scroll-offset='0%,-40%' data-scroll data-scroll-speed={-5} ref={title} as='h1' size={TypeHeadingSizes.H1} dangerouslySetInnerHTML={{__html: data.Title}}>
      </Headline>
      <CoverWrapper ref={cover}>
        <Cover data={{ Upload: data.Cover }} />
      </CoverWrapper>
      <Description ref={subtitle} as='h2' size={TypeHeadingSizes.H2} dangerouslySetInnerHTML={{__html: data.Description}}></Description>
      {renderComponents()}
       <Footer theme='white' data={props.data.allStrapiFooter} className='footer' />
    </Container>
  )
})

export default AboutPage;
