import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeHeading } from '../../common/typography/TypeHeading';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: block;
  padding-top: 15rem;
  min-height: 100vh;
  ${minWidth(Breakpoint.M)} {
    padding-top: 33.4rem;
  }
`;

export const Headline = styled(TypeHeading)`
  margin: 0 auto;
  display: block;
  font-family: ${props => props.theme.font.helveticaNeueRegular};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -2px;
  color: white;
  margin-bottom: 11rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 22.5rem;
  }
`

export const Cover = styled(Asset)`
  width: 45vw;
  margin: 0 auto;
  margin-bottom: 11rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 23.5rem;
  }
`

export const CoverWrapper = styled.div`
  position: relative;
  z-index: 3;
`

export const Description = styled(TypeHeading)`
  margin: 0 auto;
  display: block;
  font-family: ${props => props.theme.font.cardinalRegular};
  text-align: center;
  letter-spacing: -2px;
  color: white;
  max-height: 8rem;
  z-index: 1;
  position: relative;
  ${minWidth(Breakpoint.M)} {
    max-height: 25rem;
  }

  & > i {
    font-family: ${props => props.theme.font.cardinalItalic};
  }

  & > strong {
    font-family: ${props => props.theme.font.helveticaNeueRegular};
    text-transform: uppercase;
    letter-spacing: -2px;
  }
`
