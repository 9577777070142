import React, { forwardRef, useRef, useImperativeHandle, useEffect } from "react"
import IIndexProject from '../../../interface/IIndexProject';
import ProjectListingScroll from '../ProjectListingScroll';
import { Container } from './styled';

export interface ProjectListingProps { className?: string, projects: Array<IIndexProject> };

const ProjectListing = forwardRef((props: ProjectListingProps, ref) => {

  return (
    <Container className={props.className}>
      <ProjectListingScroll projects={props.projects} />
    </Container>
  )
})

export default ProjectListing;
