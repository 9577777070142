import styled from 'styled-components';
import { TypeHeading } from '../../common/typography/TypeHeading';
import ProjectListingScrollItem from '../../molecules/ProjectListingScrollItem';

export const Container = styled.div`
  display: block;
  padding: 0 15vw;
  position: relative;
`;

export const ProjectTitle = styled(TypeHeading)`
  color: ${props => props.theme.color.white};
`

export const Item = styled(ProjectListingScrollItem)`
  position: relative;
`

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${props => props.index % 2 === 0 ? 'flex-start' : 'flex-end'};
`
