import React, { forwardRef, useContext, useEffect, useRef, useState } from "react"
import VisibilitySensor from 'react-visibility-sensor';
import { ThemeContext } from '../../Layout/Layout';
import { Container, Image, YTPlayer, VMPlayer, VideoWrapper, PlayButton } from './styled';

export interface AssetProps { className?: string, data: any, scrollSpeed: Number, autoplay: any };


const Asset = forwardRef((props: AssetProps, ref) => {

  const theme = useContext(ThemeContext)
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  let autoplay = props.autoplay || false;
  const player = useRef();

  const togglePlay = () => {
    const video = (player as any).current;
    if (video) {
      const player = video.getInternalPlayer();
      if (!player) {
        return;
      }
      if (!isPlaying) {
        player.play();
        setIsPlaying(true);
      } else {
        player.pause();
        setIsPlaying(false);
      }
    }
  }

  const playerConfig = {
    youtube: {
      playerVars: { autoplay: false, loop: autoplay, muted: autoplay }
    },
    vimeo: {
      playerOptions: {
        autoplay: false,
        loop: autoplay,
        muted: autoplay
      }
    }
  }

  useEffect(() => {
    if (autoplay) {
      if(isInViewport && !isPlaying) {
        togglePlay();
      } else if (!isInViewport && isPlaying) {
        togglePlay();
      }
    }
  }, [isInViewport])


  const getAssetUrl = () => {
    if (props.data.Upload.url && parseFloat(props.data.Upload.size) < 350) {
      return props.data.Upload.url
    }
    if (props.data.Upload.formats.large) {
      return props.data.Upload.formats.large.url
    }
    if (props.data.Upload.formats.medium) {
      return props.data.Upload.formats.medium.url
    }
    if (props.data.Upload.formats.small) {
      return props.data.Upload.formats.small.url
    }
  }

  const getElement = () => {
    if (props.data && props.data.Upload) {
      if (/jpg|jpeg|png/.test(getAssetUrl())) {
        return <Image src={`${theme.urls.assetsBase()}${getAssetUrl()}`} />
      }
    } else if (props.data && props.data.Video) {
      if (/youtube/.test(props.data.Video.URL)) {
        return (
          <VisibilitySensor
            partialVisibility={true}
            onChange={(isVisible) => {
              setIsInViewport(isVisible);
            }}
            >
            <VideoWrapper autoPlay={autoplay} onClick={togglePlay} vwidth={props.data.Video.Width} vheight={props.data.Video.Height}>
              {(!isPlaying && !autoplay) &&
                <PlayButton src='/play.svg' />
              }
              <YTPlayer ref={player} autoPlay={autoplay} config={playerConfig} playsinline width='100%' height="100%" muted={true} url={props.data.Video.URL} />
            </VideoWrapper>
          </VisibilitySensor>
        )
      }
      if (/vimeo/.test(props.data.Video.URL)) {
        return (
          <VisibilitySensor
            scrollCheck={true}
            partialVisibility={true}
            onChange={(isVisible) => {
              setIsInViewport(isVisible);
            }}
          >
            <VideoWrapper autoPlay={autoplay} onClick={togglePlay} vwidth={props.data.Video.Width} vheight={props.data.Video.Height}>
              {(!isPlaying && !autoplay) &&
                <PlayButton src='/play.svg' />
              }
              <VMPlayer ref={player} autoPlay={autoplay} config={playerConfig} playsinline width='100%' height="100%" muted={true} url={props.data.Video.URL} />
            </VideoWrapper>
          </VisibilitySensor>
        )
      }
    }
  }

  const render = () => {
    if (props['scroll-speed']) {
      return (
        <Container data-scroll data-scroll-speed={props['scroll-speed']} className={props.className}>
          {getElement()}
        </Container>
      )
    }
    return (
      <Container className={props.className}>
        {getElement()}
      </Container>
    )
  }

  return (
    <>
      {render()}
    </>
  )
})

export default Asset;
