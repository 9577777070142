export const caseData = (data, slug) => {
  let project:any = {};
  const allProjects = data.allStrapiProjects.edges.sort((a: any, b: any) => {
    const aIndex = a.node.Position ? parseInt(a.node.Position) : 0;
    const bIndex = b.node.Position ? parseInt(b.node.Position) : 0;
    return aIndex - bIndex;
  })
  const total = allProjects.length;
  allProjects.forEach(({ node }, i) => {
    if (node.Slug === slug) {
      project = { ... node};
      if (i < total - 1) {
        project.next = allProjects[i + 1].node;
      } else {
        project.next = allProjects[0].node;
      }
    }
  })
  return project;
}
