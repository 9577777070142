import React, { forwardRef } from "react"
import { Asset1, Container, Asset2, ScrollWrapper } from './styled';

export interface AboutTwoAssetsProps { className?: string, data: any };

const AboutTwoAssets = forwardRef((props: AboutTwoAssetsProps, ref) => {

  return (
    <Container className={props.className}>
      <Asset1 data={props.data.Asset1} />
      <ScrollWrapper >
        <Asset2 scroll-speed={2} data={props.data.Asset2} />
      </ScrollWrapper>
    </Container>
  )
})

export default AboutTwoAssets;
