import React, { forwardRef, useContext, useEffect, useRef } from "react"
import { Container, Header, Column1, Column2, Column3, ColumnLabel, Items } from './styled';
import { TypeParagraph, TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { AppContext } from '../../Layout/Layout';
import { archiveData } from './data';
import ArchiveItem from 'src/components/molecules/ArchiveItem';
import WebGLApp from '../../webgl/WebGLApp';
import scroller from 'src/util/scroller';
import Footer from '../../molecules/Footer';

const ArchivePage = forwardRef((props: any, ref) => {

  let el = useRef();
  let context = useContext(AppContext);
  const data = archiveData(props.data);

  useEffect(() => {
    context.setBackground('white');
    WebGLApp.archiveView.initialize(el.current)
    WebGLApp.archiveView.transitionIn()
    scroller.init(el.current);
    return () => {
      scroller.destroy();
      WebGLApp.archiveView.transitionOut()
    }
  }, [])

  const renderItems = () => {
    return data.map((project, i) => {
      return <ArchiveItem delay={i} key={i} data={project} />
    })
  }

  return (
    <Container data-scroll-section data-scroll ref={el} className={props.className}>
      <Header>
        <Column1>
          <ColumnLabel size={TypeParagraphSizes.XS}>
            Project
          </ColumnLabel>
        </Column1>
        <Column2>
          <ColumnLabel size={TypeParagraphSizes.XS}>
            Category
          </ColumnLabel>
        </Column2>
        <Column3>
          <ColumnLabel size={TypeParagraphSizes.XS}>
            Published
          </ColumnLabel>
        </Column3>
      </Header>
      <Items>
        {renderItems()}
      </Items>
       <Footer data={props.data.allStrapiFooter} className='footer' />
    </Container>
  )
})

export default ArchivePage;



