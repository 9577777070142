import IIndexProject from '../../../interface/IIndexProject';

export const indexData = (api) => {
  const data: Array<IIndexProject> = [];
  const allProjects = api.allStrapiProjects.edges.sort((a: any, b: any) => {
    const aIndex = a.node.Position ? parseInt(a.node.Position) : 2;
    const bIndex = b.node.Position ? parseInt(b.node.Position) : 2;
    return aIndex - bIndex;
  })
  allProjects.forEach(node => {
    const project = node.node;
    if (!project.Featured) return;
    const p: IIndexProject = {
      title: project.Title,
      slug: project.Slug,
      images: []
    }
    p.images.push(project.Cover);
    project.Component.forEach(component => {
      if (component.Asset1) {
        if (component.Asset1.Upload) {
          p.images.push(component.Asset1.Upload)
        }
      }
      if (component.Asset2) {
        if (component.Asset2.Upload) {
          p.images.push(component.Asset2.Upload)
        }
      }
      if (component.Asset3) {
        if (component.Asset3.Upload) {
          p.images.push(component.Asset3.Upload)
        }
      }
      if (component.Asset4) {
        if (component.Asset4.Upload) {
          p.images.push(component.Asset4.Upload)
        }
      }
    });
    data.push(p);
  });
  return data;
}
