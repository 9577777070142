import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  position: relative;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
`;

export const Img = styled.img`
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  height: ${p => p.cover ? '100%' : `${p.scale * 100}%`};
  width: ${p => p.cover ? '100%' : `${p.scale * 100}%`};
  z-index: ${p => p.cover ? 1 : 0};

`
