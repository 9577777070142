import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeParagraph } from '../../common/typography/TypeParagraph';
import { Footer } from '../../molecules/MobileMenu/styled';

export const Container = styled.div`
  display: block;
  pointer-events: none;
`

export const Wrapper = styled.div`
  display: block;
  pointer-events: none;
`

export const FooterDiv = styled.div`
  opacity: ${props => props.loaded ? 1 : 0};
`
