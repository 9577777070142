import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 7rem;
  padding-top: 7rem;
  ${minWidth(Breakpoint.M)} {
    padding-top: 7rem;
    margin-bottom: 34rem;
  }
`;

export const List = styled.ul`
  display: flex;
  max-width: 50%;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-evenly;
  ${minWidth(Breakpoint.M)} {
    flex-direction: row;
    max-width: 60%;
  }
`

export const Item = styled.li`
  display: flex;
  margin-bottom: 2.2rem;
  align-items: baseline;
`

export const Label = styled(TypeParagraph)`
  color: black;
  opacity: 0.7;
  display: block;
  font-family: ${props => props.theme.font.cardinalItalic};
  margin-right: 1.5rem;
  letter-spacing: 0.5px;
`
export const Value = styled(TypeParagraph)`
  color: black;
  display: block;
  font-family: ${props => props.theme.font.helveticaNeueRegular};
  text-transform: uppercase;
`
