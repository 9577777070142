import { useStaticQuery, graphql } from 'gatsby';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import ProjectListing from 'src/components/organisms/ProjectListing'
import Footer from '../../molecules/Footer';
import WebGLApp from '../../webgl/WebGLApp';
import { indexData } from './data';
import { Container, Wrapper, FooterDiv } from './styled';
import scroller from 'src/util/scroller';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../Layout/Layout';

const IndexPage = forwardRef((props: any, ref) => {

  let el = useRef();
  let [loaded, setLoaded] = useState(false);
  const projectList = useRef() as any;
  const renderData = indexData(props.data);
  const { t } = useTranslation();
  let context = useContext(AppContext);

  useEffect(() => {
    WebGLApp.indexView.initialize(renderData)
    WebGLApp.indexView.transitionIn()
    context.setBackground('white');
    setLoaded(true);
    scroller.init(el.current);
    return () => {
      WebGLApp.indexView.transitionOut()
      scroller.destroy();
    }
  }, [])

  return (
    <Wrapper>
      <Container data-scroll ref={el}>
        <ProjectListing ref={projectList} projects={renderData} />
        <FooterDiv loaded={loaded}>
          <Footer data={props.data.allStrapiFooter} className='footer' />
        </FooterDiv>
      </Container>
    </Wrapper>
  )
})

export default IndexPage;
