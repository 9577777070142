import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect, useContext } from "react"
import IIndexProject from '../../../interface/IIndexProject';
import { Container, Img } from './styled';
import IAsset from '../../../interface/IAsset';
import { ThemeContext } from '../../Layout/Layout'

export interface ProjectListingScrollItemProps { className?: string, project: IIndexProject, theme?: any };

const ProjectListingScrollItem = forwardRef((props: ProjectListingScrollItemProps, ref) => {
  const theme = useContext(ThemeContext)
  const el = useRef<any>();
  const images:Array<any> = [];

  const filterImages = props.project.images.filter((img) => {
    if (img && img.formats.medium && img.formats.large)  {
      return img;
    }
  })

  useImperativeHandle(ref, () => ({
    preload() {
      return new Promise((resolve: Function) => {
        filterImages.forEach((img: IAsset, index: number) => {
          if (index === 0) {
            el.current.style.width = `${img.formats.medium.width * 0.8}px`;
            el.current.style.height = `${img.formats.medium.height * 0.8}px`;
          }
        })
      })
    },
  }));
  const renderImages = filterImages.map((img: IAsset, i: number) => {
    images.push(useRef());
    const scale = Math.min(Math.random(), 0.5)
    if (i === 0) {
      return <Img cover className='image' ref={images[i]} key={i} src={`${theme.urls.assetsBase()}${img.formats.medium.url}`} />
    } else {
      return <Img scale={scale} className='image' ref={images[i]} key={i} src={`${theme.urls.assetsBase()}${img.formats.medium.url}`} />
    }
  })


  return (
    <Container ref={el} data-scroll className={props.className}>
      {renderImages}
    </Container>
  )
})

export default ProjectListingScrollItem;
