import React, { useEffect, useRef } from "react"
import AboutPage from '../components/pages/about'
import IndexPage from '../components/pages/index'
import CasePage from '../components/pages/case'
import ArchivePage from '../components/pages/archive'
import { Container } from './styled';
import { graphql, useStaticQuery } from 'gatsby';

export default function Layout(props: any) {

  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiFooter {
        edges {
          node {
            Footer {
              Address
              Copyright
              Email
              SocialMedia {
                Name
                URL
              }
              Telephone
            }
          }
        }
      }
      allStrapiAbout {
        edges {
          node {
            Description
            Title
            Components {
              Asset1 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  url
                  size
                  formats {
                    large {
                      width
                      url
                      height
                    }
                    medium {
                      width
                      url
                      height
                    }
                  }
                }
              }
              Asset2 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  url
                  size
                  formats {
                    large {
                      width
                      url
                      height
                    }
                    medium {
                      width
                      url
                      height
                    }
                  }
                }
              }
              ContactTitle
              EmailContact
              EmailJobs
              JobsTitle
              Telephone
              Title
              Image {
                url
                formats {
                  large {
                    width
                    url
                    height
                  }
                  medium {
                    width
                    url
                    height
                  }
                }
              }
              List {
                Items
                Title
              }
              strapi_component
            }
            Cover {
              url
              size
              formats {
                large {
                  width
                  url
                  height
                }
                small {
                  width
                  url
                  height
                }
                medium {
                  width
                  url
                  height
                }
              }
            }
          }
        }
      }
      allStrapiProjects {
        edges {
          node {
            Title
            Position
            Slug
            Subtitle
            Featured
            Published
            Cover {
              url
              size
              formats {
                medium {
                  url
                  width
                  height
                }
                large {
                  width
                  height
                  url
                }
              }
            }
            Component {
              strapi_component
              Title
              Subtitle
              List {
                Name
                Value
              }
              Description
              Asset1 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  url
                  size
                  formats {
                    medium {
                      width
                      height
                      url
                    }
                    large {
                      width
                      height
                      url
                    }
                  }
                }
              }
              Asset2 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  url
                  size
                  formats {
                    medium {
                      width
                      url
                      height
                    }
                    large {
                      width
                      url
                      height
                    }
                  }
                }
              }
              Asset3 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  url
                  size
                  formats {
                    medium {
                      width
                      url
                      height
                    }
                    large {
                      width
                      url
                      height
                    }
                  }
                }
              }
              Asset4 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  url
                  size
                  formats {
                    medium {
                      width
                      url
                      height
                    }
                    large {
                      width
                      url
                      height
                    }
                  }
                }
              }
            }
            categories {
              Name
              Slug
            }
          }
        }
      }
    }

  `)

  return (
    <Container>
      {props.about &&
        <AboutPage data={data}></AboutPage>
      }
      {props.index &&
        <IndexPage data={data}></IndexPage>
      }
      {props.case &&
        <CasePage data={data} slug={props.case}></CasePage>
      }
      {props.archive &&
        <ArchivePage data={data} slug={props.case}></ArchivePage>
      }
    </Container>
  )
}
