import React, { forwardRef } from "react"
import { TypeHeadingSizes } from '../../common/typography/TypeHeading';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, Title, List, ListItem, ListTitle, ListContainer } from './styled';

export interface AboutServicesProps { className?: string, data: any };

const AboutServices = forwardRef((props: AboutServicesProps, ref) => {

  const renderList = () => {
    return props.data.List.map((list, i) => {
      return (
        <List key={i}>
          <ListTitle as='p' size={TypeParagraphSizes.XS}>{list.Title}</ListTitle>
          <ListItem as='p' size={TypeParagraphSizes.S} dangerouslySetInnerHTML={{__html: list.Items}} />
        </List>
      )
    })
  }

  return (
    <Container className={props.className}>
      <Title size={TypeHeadingSizes.H4}>
        {props.data.Title}
      </Title>
      <ListContainer>
        {renderList()}
      </ListContainer>
    </Container>
  )
})

export default AboutServices;
