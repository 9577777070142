import React, { forwardRef, useRef, useImperativeHandle, useEffect } from "react"
import IIndexProject from '../../../interface/IIndexProject';
import { Container, Item, ItemContainer } from './styled';

export interface ProjectListingScrollProps { className?: string, projects: Array<IIndexProject> };

const ProjectListingScroll = forwardRef((props: ProjectListingScrollProps, ref) => {
  const items = [];
  let preloaded = false;

  useEffect(() => {
    preload();
  }, [])

  const listProjects = props.projects.map((element: IIndexProject, i: number) => {
    items.push(useRef());
    return (
      <ItemContainer key={i} index={i}>
        <Item left={0} ref={items[i]} project={element} />
      </ItemContainer>
    )
  });

  const preload = () => {
    return new Promise((resolve: Function) => {
      let itemsPreloaded = 0;
      const totalItems = items.length;
      const itemPreloaded = () => {
        itemsPreloaded += 1;
        if (itemsPreloaded >= totalItems) {
          preloaded = true;
          transitionInItems();
          resolve();
        }
      }

      items.map(item => {
        item.current.preload().then(itemPreloaded);
      })
    })
  }

  const transitionInItems = () => {
    items.map(item => {
      item.current.transitionIn(() => {});
    })
  }

  return (
    <Container className={props.className}>
      {listProjects}
    </Container>
  )
})

export default ProjectListingScroll;
